<template>
  <div>
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >
      </div>

      <div class="bx-logo-about text-center my-1">
        <img
          src="/logo/logo-sv.png"
          alt="logo"
          height="120"
        >

        <div class="-title-logo">
          <h3 class="text-primary">
            สลากสุวรรณภูมิ
          </h3>
        </div>

        <p class="small">
          แพลตฟอร์มระบบขายลอตเตอรี่ออนไลน์(สลากกินแบ่งรัฐบาลไทย)ประเภทชุด ในประเทศเกาหลีโดยที่แพลตฟอร์มของเราจะจัดเก็บสลากลงระบบในรูปแบบ
          อิเล็กทรอนิกส์ เพื่อให้ง่ายต่อการค้นหา และใช้ระบบAIในการขายบนออนไลน์
          โดยไม่ต้องตามหาเลขที่ต้องการ เพื่อเป็นการอำนวยความสะดวกให้แก่ผู้ใช้บริการ
          ที่อาศัยอยู่ในประเทศเกาหลีที่ชื่นชอบ และช่วยเพิ่มความสะดวกที่ไม่ว่าจะอยู่ที่ไหน
          เมื่อไหร่ ก็สามารถเลือกซื้อเลขที่ต้องการได้ตลอดเวลา
        </p>

        <div class="img-banner my-1">
          <img
            src="@/assets/banner/Banner1.png"
            alt="banner-suvarn"
            class="img-fluid"
          >
        </div>

        <p class="small">
          การจัดหน่ายของทางสลากสุวรรณภูมิจะใช้ค่าเงินวอนเป็นหลักเพื่อความสะดวก
          สบายการขึ้นเงินรางวัลทำได้ทันทีที่แพลตฟอร์มเรา หลังประกาศผลครบทุกเลข
          เงินรางวัลทั้งหมดจะถูกแปลงค่าเป็นเงินวอน สามารถตรวจสอบได้ที่กระเป๋าสลาก
        </p>
      </div>

    </div>
    <ThisFooter />
    <!-- </div> -->
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment-timezone'

import ThisFooter from './components/ThisFooter.vue'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    ThisFooter,
  },
  data() {
    return {
      items: [],
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
      balance: {
        balance: localStorage.getItem('balance'),
      },
      balance_withdraw: 0,
      balance_datecheck: '',
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    if (this.userData) {
      this.getBalance()
    }
  },
  methods: {
    uploadProfile() {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'
      fileInput.accept = 'image/*'

      // Store a reference to the current 'this' context
      const self = this

      fileInput.addEventListener('change', event => {
        const selectedFile = event.target.files[0]
        if (selectedFile) {
          const reader = new FileReader()

          // eslint-disable-next-line func-names
          reader.onload = function () {
            const base64Image = reader.result

            // Use the 'self' reference to call the 'Upfile' function
            self.Upfile(base64Image)
          }
          reader.readAsDataURL(selectedFile)
        }
      })

      fileInput.click()
    },
    Upfile(img) {
      this.$http
        .post('users/uploadprofile/img',
          { img })
        // eslint-disable-next-line no-unused-vars
        .then(ress => {
          this.userData.profile_img = img
          localStorage.setItem('userData', JSON.stringify(this.userData))
          window.location.reload()
        })
    },
    ShowProfile() {
      if (this.userData.profile_img) {
        return this.userData.profile_img
      }
      return '/icon/user.png'
    },
    logout() {
      localStorage.removeItem('userData')
      this.$router.push({ name: 'home' })
      window.location.reload()
    },
    getBalance() {
      this.$http
        .get('balance/getbalance')
        .then(ress => {
          // console.log(ress.data)
          this.balance = ress.data
          this.balance_datecheck = moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          localStorage.setItem('balance', JSON.stringify(ress.data.balance))
        })
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped></style>
